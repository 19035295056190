import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery,Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/school.css"
import "../../styles/header.css"
import JSONData from "../../../content/home/one-accord/history.json"


function ERI () {

	const getJSX = (json) => {
		return(
			<div className="excerpt" style={{borderBottom:"2px solid black",paddingBottom:"15px"}}>
				<h2 style={{marginBottom:"5px"}}>{json.date}</h2>

				<div>
					{json.quote}

				</div>
			</div>
		)
	}

	const jsx = JSONData.map(item =>   getJSX(item))

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content school-colors school">
				<div>
					<div className="subheader">
					   <h1 className="name"> 
					   		One Accord
				       </h1>
				                 <h2>
            Excerpts from Christian writers Past and Present
          </h2>
					</div>
					
				</div>

				<div  className = "fellowship">
				{jsx}
				</div>
				
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 